<template>
  <NuxtLink
    v-if="to"
    :to="to"
    v-bind="$attrs"
    :class="btnVariant"
    :target="
      target || (typeof to === 'string' && $isExternalUrl(to))
        ? '_blank'
        : '_self'
    "
  >
    <Component :is="iconComponent" v-if="icon" :class="iconClasses" />
    <slot />
  </NuxtLink>
  <button v-else :class="btnVariant" v-bind="$attrs">
    <Component
      :is="iconComponent"
      v-if="icon && !loading"
      :class="iconClasses"
    />
    <IconLoading v-else-if="loading" :class="loadingClasses" />
    <slot />
  </button>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    to?: string | object | null
    target?: string | null
    icon?: string | null
    iconClasses?: string
    variant?: 'primary' | 'secondary' | 'tertiary'
    size?: 'sm' | 'lg'
    loading?: boolean
  }>(),
  {
    to: null,
    target: null,
    icon: null,
    iconClasses: 'h-5 mr-2 lg:mr-3',
    variant: 'primary',
    size: 'sm',
    loading: false,
  }
)

const iconComponent = computed(() => {
  if (props.icon) {
    return resolveComponent('icon-' + props.icon)
  }
  return null
})

const btnSize = computed(() => {
  if (props.size === 'lg') {
    return 'px-10 py-3 text-base'
  } else if (props.size === 'sm') {
    return 'px-6 py-3 text-xs'
  } else {
    let classes = 'px-4 py-2 text-xs min-w-12'
    if (props.variant === 'tertiary') {
      classes += ' underline'
    }
    return classes
  }
})

const loadingClasses = computed(() => {
  if (props.size === 'lg') {
    return 'w-5 h-auto'
  } else {
    return 'w-3 h-auto'
  }
})

const btnVariant = computed(() => {
  const classes = [
    'font-bold inline-flex items-center text-center transition duration-200 rounded-sm relative justify-center',
    btnSize.value,
  ]

  if (props.variant === 'primary') {
    classes.push(
      'bg-gray-700 border border-gray-700 text-white hover:bg-white hover:text-gray-700 disabled:border-gray-50 disabled:bg-gray-50 disabled:text-gray-200'
    )
  }

  if (props.variant === 'secondary') {
    classes.push(
      'border border-gray-500 bg-white text-gray-800 hover:text-white hover:border-white hover:bg-gray-800 hover:border-black'
    )
  }

  if (props.variant === 'tertiary') {
    classes.push('bg-white text-gray-800 hover:text-white hover:bg-gray-500')
  }

  return classes
})
</script>
