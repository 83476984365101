import { type NavigationsAndGlobalsQuery } from '#gql'
import type { Schemas } from '#shopware'

type ReturnType = {
  loadGlobals: () => Promise<NavigationsAndGlobalsQuery>
  loadNavigation: () => Promise<Schemas['NavigationRouteResponse']>
}

export const useShopNavigation = () =>
  useState<Schemas['NavigationRouteResponse']>('shopNavigation', () => [])

export const useCmsGlobals = () =>
  useState<NavigationsAndGlobalsQuery>(
    'cms',
    () => ({}) as Enforce<NavigationsAndGlobalsQuery>
  )

export const useGlobals = (): ReturnType => {
  const { locale } = useI18n()
  const { loadNavigationElements } = useNavigation()

  const loadGlobals = async () => {
    const { data: cmsData, error } = await useAsyncGql({
      operation: 'NavigationsAndGlobals',
      variables: { lang: locale },
    })
    if (!cmsData.value || error.value) {
      throw createError({
        statusCode: 500,
        message: 'Unable to fetch global statamic data',
        cause: error.value ?? 'Unkown',
        fatal: true,
      })
    }
    return cmsData.value
  }

  const loadNavigation = async () => {
    return await loadNavigationElements({ depth: 4 })
  }

  return {
    loadGlobals,
    loadNavigation,
  }
}
