import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.17.4_encoding@0.1.13_eslint@8.57.1_ioredis@5_lulxhjz3padjop72p7pvexz5eq/node_modules/nuxt/dist/pages/runtime/validate.js";
import notifications_45global from "/vercel/path0/middleware/notifications.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.17.4_encoding@0.1.13_eslint@8.57.1_ioredis@5_lulxhjz3padjop72p7pvexz5eq/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  notifications_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "previous-route": () => import("/vercel/path0/middleware/previous-route.ts")
}