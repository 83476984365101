<template>
  <Component
    :is="tag"
    class="px-4"
    :class="{
      'mx-auto max-w-[clamp(1715px,80vw,9999px)]': size !== 'lg',
      'lg:px-6': size === 'lg',
      'lg:px-8 xl:px-16': size === 'md',
      'lg:px-40 xl:px-80': size === 'sm',
    }"
  >
    <slot />
  </Component>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    tag?: string
    // legacy prop value TODO: replace all occurrences
    indent?: 'none' | 'default' | 'tight' | 'tighter' | 'center' | 'content'
  }>(),
  {
    tag: 'div',
    indent: 'default',
  }
)

const size = computed(() => {
  switch (props.indent) {
    case 'none':
      return 'lg'
    case 'center':
    case 'content':
      return 'sm'
    default:
      return 'md'
  }
})
</script>
