import { defineRule, configure } from 'vee-validate'
import { required, email, numeric, max, url, mimes } from '@vee-validate/rules'
import { localize, setLocale } from '@vee-validate/i18n'
import { setLocale as yupSetLocale } from 'yup'
import de from '@vee-validate/i18n/dist/locale/de.json'
import fr from '@vee-validate/i18n/dist/locale/fr.json'

export default defineNuxtPlugin((_) => {
  defineRule('required', required)
  defineRule('email', email)
  defineRule('numeric', numeric)
  defineRule('integer', numeric)
  defineRule('max', max)
  defineRule('url', url)
  defineRule('mimes', mimes)

  defineRule('phone', (value: any) => {
    // Field is empty, should pass
    if (!value || !value.length) {
      return true
    }
    const regex = /^(\+|00)[1-9][0-9 \-\(\)\.]{7,32}$/
    return regex.test(value)
  })

  // to handle the nullable fields rules from statamic
  defineRule('nullable', () => {
    return true
  })

  configure({
    // controls if `blur` events should trigger validation with `handleChange` handler
    validateOnBlur: true,
    // controls if `change` events should trigger validation with `handleChange` handler
    validateOnChange: true,
    // controls if `input` events should trigger validation with `handleChange` handler
    validateOnInput: true,
    // controls if `update:modelValue` events should trigger validation with `handleChange` handler
    validateOnModelUpdate: true,

    generateMessage: localize({
      de,
      fr,
    }),
  })

  const { $i18n } = useNuxtApp()

  setLocale($i18n.locale.value)

  // set correct locale on language switched
  $i18n.onLanguageSwitched = (__: string, newLocale: string) => {
    setLocale(newLocale)
    return Promise.resolve()
  }

  const t = $i18n.t

  yupSetLocale({
    mixed: {
      required: t('form.validation.required'),
    },
    string: {
      email: t('form.validation.email'),
      url: t('form.validation.url'),
      min: ({ min }) => t('form.validation.minString', { min }),
      max: ({ max }) => t('form.validation.maxString', { max }),
      length: t('form.validation.length'),
      lowercase: t('form.validation.lowercase'),
      uppercase: t('form.validation.uppercase'),
    },
    number: {
      min: ({ min }) => t('form.validation.min', { min }),
      max: ({ max }) => t('form.validation.max', { max }),
    },
  })
})
