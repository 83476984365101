import revive_payload_client_MIc5x1gUCw from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.17.4_encoding@0.1.13_eslint@8.57.1_ioredis@5_lulxhjz3padjop72p7pvexz5eq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_LiGkyc7J4g from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.17.4_encoding@0.1.13_eslint@8.57.1_ioredis@5_lulxhjz3padjop72p7pvexz5eq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_wPA4SmqUox from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.17.4_encoding@0.1.13_eslint@8.57.1_ioredis@5_lulxhjz3padjop72p7pvexz5eq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_1kSTP6saOO from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.17.4_encoding@0.1.13_eslint@8.57.1_ioredis@5_lulxhjz3padjop72p7pvexz5eq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_fxZsG6atJn from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.17.4_encoding@0.1.13_eslint@8.57.1_ioredis@5_lulxhjz3padjop72p7pvexz5eq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_ECPXdJmsWj from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.3_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_0iOJK0wedr from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.17.4_encoding@0.1.13_eslint@8.57.1_ioredis@5_lulxhjz3padjop72p7pvexz5eq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_1oz0t9FCKf from "/vercel/path0/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.5_rollup@4.24.3_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_QJefZLciEx from "/vercel/path0/node_modules/.pnpm/nuxt-graphql-client@0.2.36_@parcel+watcher@2.4.1_@types+node@20.17.4_encoding@0.1.13_graphql-_hzcjnixtml7gm5tfvqu42wrgiu/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import i18n_aOTtwGzioo from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.5_rollup@4.24.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_qB7jQMg7ee from "/vercel/path0/node_modules/.pnpm/@shopware-pwa+nuxt3-module@1.0.5_magicast@0.3.5_rollup@4.24.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@shopware-pwa/nuxt3-module/plugin.ts";
import chunk_reload_client_ap9IK2SnK3 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@20.17.4_encoding@0.1.13_eslint@8.57.1_ioredis@5_lulxhjz3padjop72p7pvexz5eq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import inlineUtils_X58FG1MpoS from "/vercel/path0/node_modules/.c12/github_teamnovu_nuxt_m1o5eah4I2/plugins/inlineUtils.ts";
import livePreview_RrjgcDm40s from "/vercel/path0/node_modules/.c12/github_teamnovu_nuxt_m1o5eah4I2/plugins/livePreview.ts";
import onError_lHlIdZiLD7 from "/vercel/path0/node_modules/.c12/github_teamnovu_nuxt_m1o5eah4I2/plugins/onError.ts";
import vue_cloudinary_image_GGoxRgqUcV from "/vercel/path0/node_modules/.c12/github_teamnovu_nuxt_m1o5eah4I2/plugins/vue-cloudinary-image.ts";
import cookieconsent_hBUClLyRD3 from "/vercel/path0/plugins/cookieconsent.ts";
import live_preview_rAaFLcR04D from "/vercel/path0/plugins/live-preview.js";
import veeValidate_U1l0qzVbBC from "/vercel/path0/plugins/veeValidate.ts";
export default [
  revive_payload_client_MIc5x1gUCw,
  unhead_LiGkyc7J4g,
  router_wPA4SmqUox,
  payload_client_1kSTP6saOO,
  check_outdated_build_client_fxZsG6atJn,
  plugin_vue3_ECPXdJmsWj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_0iOJK0wedr,
  plugin_client_1oz0t9FCKf,
  plugin_QJefZLciEx,
  i18n_aOTtwGzioo,
  plugin_qB7jQMg7ee,
  chunk_reload_client_ap9IK2SnK3,
  inlineUtils_X58FG1MpoS,
  livePreview_RrjgcDm40s,
  onError_lHlIdZiLD7,
  vue_cloudinary_image_GGoxRgqUcV,
  cookieconsent_hBUClLyRD3,
  live_preview_rAaFLcR04D,
  veeValidate_U1l0qzVbBC
]