export default {
  de: {
    consentModal: {
      title: 'Cookies &amp; Tracking',
      description:
        'Wir verwenden Cookies, um das Nutzererlebnis auf unserer Webseite zu verbessern und unseren Datenverkehr zu analysieren.',
      acceptAllBtn: 'Alle akzeptieren',
      acceptNecessaryBtn: 'Alle ablehnen',
      showPreferencesBtn: 'Auswahl erlauben',
    },
    preferencesModal: {
      title: 'Cookie-Einstellungen',
      acceptAllBtn: 'Alle akzeptieren',
      acceptNecessaryBtn: 'Alle ablehnen',

      savePreferencesBtn: 'Einstellungen speichern',
      closeIconLabel: 'Schliessen',
      sections: [
        {
          title: 'Cookie Nutzung',
          description:
            'Wir verwenden Cookies, um die grundlegenden Funktionen der Website zu gewährleisten und um Ihr Online-Erlebnis zu verbessern. Sie können für jede Kategorie wählen, ob Sie sich ein- oder austragen möchten, wann immer Sie wollen.',
        },
        {
          title: 'Notwendige Cookies',
          description:
            'Diese Cookies sind für das ordnungsgemässe Funktionieren der Website unerlässlich.',
          linkedCategory: 'necessary',
          cookieTable: {
            headers: {
              name: 'Name',
              description: 'Description',
              expiration: 'Gültigkeit',
            },
            body: [
              {
                name: 'Consent Cookie',
                description: 'Speichern der Cookieauswahl',
                expiration: '6 Monate',
              },
              {
                name: 'Sprachauswahl',
                description: 'Speichern der Sprachauswahl',
                expiration: 'Session',
              },
            ],
          },
        },
        // {
        //   title: 'Funktionale Cookies',
        //   description:
        //     'Diese Cookies ermöglichen die erweiterte Nutzung der Webseite, wie das Laden von Videos welche über dritte Anbieter eingebunden werden oder das Absenden geschützter Formulare.',
        //   linkedCategory: 'functional',
        // },
        {
          title: 'Analyse Cookies',
          description:
            'Statistik-Cookies helfen Website-Besitzern zu verstehen, wie Besucher mit Websites interagieren, indem sie Informationen sammeln und melden.',
          linkedCategory: 'analytics',
          cookieTable: {
            headers: {
              name: 'Name',
              description: 'Description',
              expiration: 'Gültigkeit',
            },
            body: [
              {
                name: 'Google Analytics',
                description: 'Cookies von Google Analytics',
                expiration: '2 Wochen',
              },
              // {
              //   name: 'Facebook',
              //   description: 'Cookies von Facebook',
              //   expiration: '2 Wochen',
              // },
            ],
          },
        },
      ],
    },
  },
  en: {
    consentModal: {
      title: 'Cookies &amp; Tracking',
      description:
        'We use cookies to improve the user experience on our website and to analyze our traffic.',
      acceptAllBtn: 'Accept all',
      acceptNecessaryBtn: 'Reject all',
      showPreferencesBtn: 'Manage preferences',
    },
    preferencesModal: {
      title: 'Cookie preferences',
      acceptAllBtn: 'Accept all',
      acceptNecessaryBtn: 'Reject all',

      savePreferencesBtn: 'Save preferences',
      closeIconLabel: 'Close modal',
      sections: [
        {
          title: 'Cookie Usage',
          description:
            'We use cookies to ensure the basic functions of the website and to improve your online experience. You can choose to opt in or out for each category whenever you want.',
        },
        {
          title: 'Necessary Cookies',
          description:
            'These cookies are essential for the proper functioning of my website.',
          linkedCategory: 'necessary',
          cookieTable: {
            headers: {
              name: 'Name',
              description: 'Description',
              expiration: 'Expiration',
            },
            body: [
              {
                name: 'Consent Cookie',
                description: 'Saves the cookie selection',
                expiration: '6 months',
              },
              {
                name: 'Language selection',
                description: 'Saves the language selection',
                expiration: 'Session',
              },
            ],
          },
        },
        // {
        //   title: 'Functional Cookies',
        //   description:
        //     'These cookies allow for the extended use of the website, such as loading videos which are embedded by third parties or submitting secure forms.',
        //   linkedCategory: 'functional',
        // },
        {
          title: 'Analytics Cookies',
          description:
            'Cookies that help website owners understand how visitors interact with websites by collecting and reporting information.',
          linkedCategory: 'analytics',
          cookieTable: {
            headers: {
              name: 'Name',
              description: 'Description',
              expiration: 'Expiration',
            },
            body: [
              // {
              //   name: 'Google Analytics',
              //   description: 'Cookies from Google Analytics',
              //   expiration: '2 weeks',
              // },
              // {
              //   name: 'Facebook',
              //   description: 'Cookies from Facebook',
              //   expiration: '2 weeks',
              // },
            ],
          },
        },
      ],
    },
  },
  fr: {
    consentModal: {
      title: 'Cookies &amp; Tracking',
      description:
        'Nous utilisons des cookies pour améliorer votre expérience sur notre site Web et pour analyser notre trafic.',
      acceptAllBtn: 'Tout accepter',
      acceptNecessaryBtn: 'Tout refuser',
      showPreferencesBtn: 'Gérer les préférences',
    },
    preferencesModal: {
      title: 'Préférences de cookies',
      acceptAllBtn: 'Tout accepter',
      acceptNecessaryBtn: 'Tout refuser',

      savePreferencesBtn: 'Enregistrer les préférences',
      closeIconLabel: 'Fermer',
      sections: [
        {
          title: 'Utilisation des cookies',
          description:
            "Nous utilisons des cookies pour assurer les fonctionnalités de base du site Web et pour améliorer votre expérience en ligne. Vous pouvez choisir d'accepter ou de refuser pour chaque catégorie à tout moment.",
        },
        {
          title: 'Cookies nécessaires',
          description:
            'Ces cookies sont essentiels pour le bon fonctionnement de mon site Web.',
          linkedCategory: 'necessary',
          cookieTable: {
            headers: {
              name: 'Nom',
              description: 'Description',
              expiration: 'Expiration',
            },
            body: [
              {
                name: 'Cookie de consentement',
                description: 'Enregistre la sélection du cookie',
                expiration: '6 mois',
              },
              {
                name: 'Sélection de la langue',
                description: 'Enregistre la sélection de la langue',
                expiration: 'Session',
              },
            ],
          },
        },
        // {
        //   title: 'Cookies fonctionnels',
        //   description:
        //     "Ces cookies permettent l'utilisation étendue du site Web, comme le chargement de vidéos qui sont intégrées par des tiers ou le soumission de formulaires sécurisés.",
        //   linkedCategory: 'functional',
        // },
        {
          title: "Cookies d'analyse",
          description:
            'Cookies qui aident les propriétaires de sites Web à comprendre comment les visiteurs interagissent avec les sites Web en collectent et en rapportant des informations.',
          linkedCategory: 'analytics',
          cookieTable: {
            headers: {
              name: 'Nom',
              description: 'Description',
              expiration: 'Expiration',
            },
            body: [
              {
                name: 'Google Analytics',
                description: 'Cookies de Google Analytics',
                expiration: '2 semaines',
              },
              // {
              //   name: 'Facebook',
              //   description: 'Cookies de Facebook',
              //   expiration: '2 semaines',
              // },
            ],
          },
        },
      ],
    },
  },
  it: {
    consentModal: {
      title: 'Cookies &amp; Tracking',
      description:
        "Utilizziamo i cookie per migliorare l'esperienza utente sul nostro sito Web e analizzare il nostro traffico.",
      acceptAllBtn: 'Accetta tutto',
      acceptNecessaryBtn: 'Rifiuta tutto',
      showPreferencesBtn: 'Gestisci preferenze',
    },
    preferencesModal: {
      title: 'Preferenze cookie',
      acceptAllBtn: 'Accetta tutto',
      acceptNecessaryBtn: 'Rifiuta tutto',

      savePreferencesBtn: 'Salva preferenze',
      closeIconLabel: 'Chiudi',
      sections: [
        {
          title: 'Utilizzo dei cookie',
          description:
            "Utilizziamo i cookie per garantire le funzionalità di base del sito Web e migliorare l'esperienza online.",
        },
        {
          title: 'Cookie necessari',
          description:
            'Questi cookie sono essenziali per il funzionamento corretto del mio sito Web.',
          linkedCategory: 'necessary',
          cookieTable: {
            headers: {
              name: 'Nome',
              description: 'Descrizione',
              expiration: 'Scadenza',
            },
            body: [
              {
                name: 'Cookie di consenso',
                description: 'Salva la selezione del cookie',
                expiration: '6 mesi',
              },
              {
                name: 'Selezione della lingua',
                description: 'Salva la selezione della lingua',
                expiration: 'Sessione',
              },
            ],
          },
        },
        // {
        //   title: 'Cookie funzionali',
        //   description:
        //     "Questi cookie consentono l'utilizzo esteso del sito Web, come il caricamento di video che sono integrati da terzi o la presentazione di moduli sicuri.",
        //   linkedCategory: 'functional',
        // },
        {
          title: 'Cookie di analisi',
          description:
            'Cookie che aiutano i proprietari di siti Web a comprendere come gli utenti interagiscono con i siti Web raccogliendo e riportando informazioni.',
          linkedCategory: 'analytics',
          cookieTable: {
            headers: {
              name: 'Nome',
              description: 'Descrizione',
              expiration: 'Scadenza',
            },
            body: [
              {
                name: 'Google Analytics',
                description: 'Cookie di Google Analytics',
                expiration: '2 settimane',
              },
              // {
              //   name: 'Facebook',
              //   description: 'Cookie di Facebook',
              //   expiration: '2 settimane',
              // },
            ],
          },
        },
      ],
    },
  },
}
