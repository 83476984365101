<template>
  <NuxtLoadingIndicator color="black" :throttle="0" :height="6" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
const runtimeConfig = useRuntimeConfig()
const { getWishlistProducts } = useWishlist()
const { loadGlobals } = useGlobals()
const { loadNavigation } = useGlobals()
const { locale, localeProperties } = useI18n()
const { refreshCart } = useCart()
const { apiClient } = useShopwareContext()
const { refreshSessionContext } = useSessionContext()
const { changeLanguage } = useInternationalization()

const cms = useCmsGlobals()
const shopNavigation = useShopNavigation()

// Shopware prefix
provide('urlPrefix', runtimeConfig.public.SHOP_PREFIX)

// notifications won't work without initializing them first
useNotifications()

// Use SSR-safe IDs for Headless UI
provideHeadlessUseId(() => useId())

useHead({
  titleTemplate: (title) =>
    title
      ? `${process.dev ? '🚧 ' : ''}${title} - JP Rosselet Cosmetics`
      : `${process.dev ? '🚧 ' : ''}JP Rosselet Cosmetics`,
})

const cmsGlobalsPromise = useAsyncData('globals', async () => {
  return await loadGlobals()
})

const shopNavigationPromise = useAsyncData('shopNavigation', async () => {
  return await loadNavigation()
})

const { data: cmsGlobalsData } = await cmsGlobalsPromise
cms.value = cmsGlobalsData.value || {}

const { data: shopNavigationData } = await shopNavigationPromise
shopNavigation.value = shopNavigationData.value

await refreshSessionContext()

watch(
  locale,
  async () => {
    const languageId = localeProperties.value.languageId
    await changeLanguage(languageId)
    apiClient.defaultHeaders.apply({
      'sw-language-id': languageId,
    })
  },
  { immediate: true }
)

onMounted(() => {
  if (import.meta.client) {
    getWishlistProducts()
    refreshCart()
  }
})
</script>
